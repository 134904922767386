import { isServer } from '@tanstack/react-query';
import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios';

import { ROUTES } from '@/shared/routes';
import { HeaderName } from '@/shared/types';

import { STORAGE_KEYS } from './constants';
import { restoreServerAccessToken } from './server-utils';
import {
  getExistingDeviceInfo,
  removeToken,
  restoreToken,
  storeTokens,
} from './utils';

import { config } from '@/config';

export async function withAccessToken(config: InternalAxiosRequestConfig) {
  if (config.headers['X-Skip-Auth']) {
    // Remove the header if auth is skipped
    delete config.headers['X-Skip-Auth'];

    return config;
  }

  if (config.headers[HeaderName.DeviceId]) {
    if (isServer) {
      delete config.headers[HeaderName.DeviceId];
    } else {
      const deviceInfo = getExistingDeviceInfo();

      if (deviceInfo?.deviceId) {
        config.headers[HeaderName.DeviceId] = deviceInfo.deviceId;
      }
    }
  }

  const token = isServer
    ? await restoreServerAccessToken()
    : restoreToken(STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY);

  if (token) {
    // Set the Authorization header if token exists
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

export function with401Redirect(error: AxiosError) {
  if (isAxiosError(error) && error.response?.status === 401) {
    if (!isServer) {
      // Remove the token if the error is a 401
      removeToken(STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY);
      removeToken(STORAGE_KEYS.CLIENT_REFRESH_TOKEN_STORAGE_KEY);
      // Redirect to the login page with the current path as the redirect URL
      window.location.href = ROUTES.$SIGN_IN(window.location.pathname);
    }
  }

  return Promise.reject(error);
}

export function createApi(
  baseURL: string,
  { headers = {} }: { headers?: Record<string, string> } = {},
) {
  const api = axios.create({ baseURL, headers: { ...headers } });
  api.interceptors.request.use(withAccessToken);
  api.interceptors.response.use(undefined, with401Redirect);

  return api;
}

export const mockApi = createApi('/api/v1/mocks');
export const baseApi = createApi(config.API_BASE_URL);
export const walletApi = createApi(config.WALLET_API_BASE_URL);
export const referralApi = createApi(`${config.API_BASE_URL}/referral/v1`);
export const identityApi = createApi(`${config.API_BASE_URL}/identity/v1`);
export const beneficiaryApi = createApi(config.BENEFICIARY_API_BASE_URL);
export const interbankTransferApi = createApi(
  config.INTERBANK_TRANSFER_API_BASE_URL,
  {
    headers: {
      Accept: 'application/json',
    },
  },
);
export const xcallyAPI = createApi(config.XCALLY_API_BASE_URL);

export async function refreshTokens() {
  const refreshToken = restoreToken(
    STORAGE_KEYS.CLIENT_REFRESH_TOKEN_STORAGE_KEY,
  );
  if (!refreshToken) throw new Error('No refresh token available');
  const maxRetries = 3;
  let retryCount = 0;
  let retryInterval = 1000;

  while (retryCount < maxRetries) {
    try {
      const response = await identityApi.post('/tokens', { refreshToken });
      const { accessToken, refreshToken: newRefreshToken } = response.data;
      storeTokens([
        {
          key: STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY,
          token: accessToken,
        },
        {
          key: STORAGE_KEYS.CLIENT_REFRESH_TOKEN_STORAGE_KEY,
          token: newRefreshToken,
        },
      ]);

      return accessToken;
    } catch (error) {
      retryCount++;
      if (retryCount >= maxRetries) {
        throw error;
      }

      // Wait before retrying
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
      retryInterval *= 2;
    }
  }
}
