'use client';

import { AlertModal, AlertModalProps } from '@kamona/components';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';

export const useAlertDialog = () => {
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null);
  const rootRef = React.useRef<ReactDOM.Root | null>(null);

  useEffect(() => {
    return () => {
      if (rootRef.current) {
        rootRef.current.unmount();
        rootRef.current = null;
      }
      if (container) {
        document.body.removeChild(container);
        setContainer(null);
      }
    };
  }, []);

  const createAlertDialog = (props: AlertModalProps) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    setContainer(div);

    const root = ReactDOM.createRoot(div);
    rootRef.current = root;

    root.render(
      <AlertModal
        defaultOpen
        description="This is a description"
        size="small"
        title="Main Title"
        type="warning"
        {...props}
      />,
    );
  };

  return { createAlertDialog };
};
