'use client';

import CryptoJS from 'crypto-js';
import {
  useSearchParams as useNextSearchParams,
  usePathname,
  useRouter,
} from 'next/navigation';
import { useCallback } from 'react';

import { isClient, logError } from '@/lib/utils';

import { config } from '@/config';

const ENCRYPTION_KEY = config.SEARCH_PARAM_ENCRYPTION_KEY;
const HMAC_KEY = config.SEARCH_PARAM_HMAC_KEY;

type CreateSignedEncryptedStringParams = {
  searchParams?: URLSearchParams;
  newParams: Record<string, any>;
};

/**
 * Encrypts and signs the parameters and returns a single "q" query parameter
 * @param params
 * @returns string
 */
export const encryptAndSignParams = (params: Record<string, any>): string => {
  const jsonString = JSON.stringify(params);
  const encryptedData = CryptoJS.AES.encrypt(
    jsonString,
    ENCRYPTION_KEY,
  ).toString();
  const signature = CryptoJS.HmacSHA256(encryptedData, HMAC_KEY).toString();
  return `${encryptedData}.${signature}`;
};

/**
 * verifies and decrypts a signed encrypted string
 * @param signedEncryptedString string
 * @returns object
 */
export const verifyAndDecryptParams = (
  signedEncryptedString: string,
): Record<string, any> | null => {
  const [encryptedData, signature] = signedEncryptedString.split('.');
  // invalid
  if (!encryptedData || !signature) return null;

  // Verify the signature to detect tampering
  const expectedSignature = CryptoJS.HmacSHA256(
    encryptedData,
    HMAC_KEY,
  ).toString();
  if (signature !== expectedSignature) {
    logError('Signature mismatch: data may have been tampered with.');
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
  } catch (error) {
    logError('Failed to decrypt search params:', error);
    return null;
  }
};

/**
 * sanitize string to prevent XSS
 * @param value string
 * @returns
 */
export const sanitizeString = (value: string): string =>
  value.replace(/(<|>|%3C|%3E)/g, '');

/**
 * create signed encrypted string
 * @param searchParams
 * @param newParams
 * @returns
 */
export const createSignedEncryptedString = ({
  searchParams = new URLSearchParams(isClient() ? window.location.search : ''),
  newParams,
}: CreateSignedEncryptedStringParams) => {
  // Retrieve and decrypt existing parameters if present
  const existingEncryptedData = searchParams.get('q');
  const existingParams = existingEncryptedData
    ? verifyAndDecryptParams(existingEncryptedData)
    : {};
  // Sanitize and merge new parameters with existing ones
  const sanitizedNewParams: Record<string, string> = {};
  for (const [key, value] of Object.entries(newParams)) {
    sanitizedNewParams[key] = sanitizeString(String(value));
  }
  const mergedParams = { ...existingParams, ...sanitizedNewParams };
  // Encrypt and sign merged parameters and update the URL with a single "q" parameter
  const signedEncryptedString = encryptAndSignParams(mergedParams);
  return signedEncryptedString;
};

export function addSearchParamsUtil({
  newParams,
}: {
  newParams: Record<string, any>;
}) {
  const searchParams = new URLSearchParams();
  const signedEncryptedString = createSignedEncryptedString({
    newParams,
  });
  // Set single signed encrypted key
  searchParams.set('q', signedEncryptedString);
  return searchParams;
}

export function useSearchParams<T extends Record<string, any>>() {
  const router = useRouter();
  const currentPathname = usePathname();
  const searchParams = useNextSearchParams();

  // Merges new parameters with existing ones, encrypts, signs, and updates the URL
  const addParams = useCallback(
    (newParams: T) => {
      const signedEncryptedString = createSignedEncryptedString({
        searchParams,
        newParams,
      });
      const newSearchParams = new URLSearchParams();
      // Set single signed encrypted key
      newSearchParams.set('q', signedEncryptedString);
      return newSearchParams;
    },
    [searchParams],
  );

  const routerReplaceWithParams = useCallback(
    ({
      params,
      pathname = currentPathname,
    }: {
      params: URLSearchParams;
      pathname?: string;
    }) => {
      router.replace(`${pathname}?${params.toString()}`, {
        scroll: false,
      });
    },
    [router, currentPathname],
  );

  const routerPushWithParams = useCallback(
    ({
      params,
      pathname = currentPathname,
    }: {
      params: URLSearchParams;
      pathname?: string;
    }) => {
      router.push(`${pathname}?${params.toString()}`, {
        scroll: false,
      });
    },
    [router, currentPathname],
  );

  // Retrieves, verifies, and decrypts the single "data" query parameter into an object
  const getParams = (): Record<string, any> | null => {
    const signedEncryptedData = searchParams.get('q');
    if (signedEncryptedData) {
      return verifyAndDecryptParams(signedEncryptedData);
    }
    return null;
  };

  const getParam = (key: string) => {
    const params = getParams();
    if (params) {
      return params[key];
    }
    return null;
  };

  const hasParam = (key: string) => {
    const params = getParams();
    if (params) {
      return Object.keys(params).includes(key);
    }
    return false;
  };

  const deleteParams = (keys: string[]) => {
    const allParams = getParams();
    if (allParams) {
      const newParams = { ...allParams };
      for (const key of keys) {
        delete newParams[key];
      }
      const signedEncryptedString = encryptAndSignParams(newParams);
      const newSearchParams = new URLSearchParams();
      // Set single signed encrypted key
      newSearchParams.set('q', signedEncryptedString);
      return newSearchParams;
    }
    return null;
  };

  const deleteExternalSearchParams = useCallback(
    (keys: string[]) => {
      const newSearchParams = new URLSearchParams(searchParams);

      for (const key of keys) {
        newSearchParams.delete(key);
      }

      router.replace(`${currentPathname}?${newSearchParams.toString()}`, {
        scroll: false,
      });
    },
    [router, currentPathname, searchParams],
  );

  return {
    addParams,
    getParams,
    hasParam,
    getParam,
    routerReplaceWithParams,
    routerPushWithParams,
    searchParams,
    deleteParams,
    deleteExternalSearchParams,
  };
}
