'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

const useDisableBack = () => {
  const router = useRouter();

  useEffect(() => {
    let isListening = true;

    const pushDummyState = () => {
      if (isListening) {
        window.history.pushState(null, '', window.location.href);
      }
    };

    pushDummyState();

    window.addEventListener('popstate', pushDummyState);

    return () => {
      isListening = false;
      window.removeEventListener('popstate', pushDummyState);
    };
  }, [router]);
};

export default useDisableBack;
