import { classes, defineVariants } from '@/ui/system';

export type TextVariants = typeof textVariants;

export const textVariants = defineVariants({
  variants: {
    align: {
      start: 'text-left',
      center: 'text-center',
      end: 'text-right',
      mobileCenter: 'text-center md:text-left',
    },
    color: classes.textColor,
    variant: {
      'header-default':
        'font-general-sans text-header-small lg:text-header-default leading-header-default tracking-header-default',
      'header-small':
        'font-general-sans text-header-small leading-header-small tracking-header-small',
      'body-large':
        'font-inter text-body-large leading-body-large tracking-body-large',
      'header-small-subtitle':
        'font-general-sans text-subtitle leading-subtitle tracking-subtitle md:text-header-small md:leading-header-small md:tracking-header-small',
      'body-default':
        'font-inter text-body-default leading-body-default tracking-body-default',
      'body-small':
        'font-inter text-body-small leading-body-small tracking-body-small',
      'body-x-small':
        'font-inter text-body-x-small leading-body-x-small tracking-body-x-small',
      subtitle: 'font-inter text-subtitle leading-subtitle tracking-subtitle',
      inherit: 'inherit',
    },
    weight: {
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
      heading: 'font-580',
    },
  },
  compoundVariants: [
    {
      direction: 'column',
      reversed: true,
      class: 'flex-col-reverse',
    },
    {
      direction: 'row',
      reversed: true,
      class: 'flex-row-reverse',
    },
  ],
  defaultVariants: {
    align: 'start',
    color: 'content-heading-default',
    variant: 'inherit',
    weight: 'normal',
  },
});
