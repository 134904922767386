"use client"

import { useEffect } from 'react';

interface UseModalResetProps {
  isSuccess: boolean;
  open?: boolean;
  reset: () => void;
  onOpenChange?: (open: boolean) => void;
}

export function useDialogReset({
  isSuccess,
  open,
  reset,
  onOpenChange,
}: UseModalResetProps) {
  useEffect(() => {
    if (isSuccess) {
      reset();
      onOpenChange?.(false);
    }
  }, [isSuccess, onOpenChange, reset]);

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);
}
