'use client';
import { useState } from 'react';
import { useIdleTimer as useReactIdleTime } from 'react-idle-timer';

export function useIdleTimer(idleTime: number) {
  const [isIdle, setIsIdle] = useState(false);
  const onIdle = () => {
    setIsIdle(true);
  };
  const onActive = () => {
    setIsIdle(false);
  };

  useReactIdleTime({
    onIdle,
    onActive,
    timeout: idleTime,
  });

  return isIdle;
}
