import {
  QueryFunction,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { baseApi } from '@/lib/api';

import { ApiError, HeaderName } from '../types';

export type UseHttpQueryAxiosOptions<Data> = Pick<
  AxiosRequestConfig<Data>,
  'url' | 'data' | 'method' | 'headers' | 'params'
>;

export type UseHttpQueryOptions<Data, Response, Selected, Error> =
  UseHttpQueryAxiosOptions<Data> &
    UseQueryOptions<Response, Error, Selected> & {
      /** Optional axios instance */
      api?: AxiosInstance;
      /** If true, the request will not have the Authorization header */
      disableAuth?: boolean;
      /** If true, the request will have the device id header */
      appendDeviceId?: boolean;
    };

/**
 * @example
 * const { data, isLoading, error } = useHttpQuery({
 *  url: "/api/user",
 *  enabled: !!token,
 *  select: (data) => data.user,
 *  headers: {
 *    Authorization: `Bearer ${token}`,
 *  }
 * }
 */
export function useHttpQuery<
  Data = unknown,
  Response = unknown,
  Selected = Response,
  Error = ApiError,
>({
  api,
  url,
  data,
  params,
  headers,
  method = 'GET',
  disableAuth = false,
  appendDeviceId = false,
  ...queryOptions
}: UseHttpQueryOptions<Data, Response, Selected, Error>) {
  const queryFn: QueryFunction<Response> = async (): Promise<Response> => {
    const response: AxiosResponse<Response> = await (api || baseApi).request({
      url,
      data,
      params,
      method,
      headers: {
        ...headers,
        ...(disableAuth ? { 'X-Skip-Auth': 'true' } : {}),
        ...(appendDeviceId ? { [HeaderName.DeviceId]: true } : {}),
      },
    });
    return response.data;
  };

  return useQuery<Response, Error, Selected>({
    queryFn,
    ...queryOptions,
  });
}
